/* eslint-disable no-prototype-builtins */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as ExcelJS from 'exceljs';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import autoTable from 'jspdf-autotable';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { rfpNameSchema } from './RfpNameSchema';
import maliciousPDFCheck from './malicious-pdf-check';
import { allowedDocsTypes, allowedFileTypes, allowedImgTypes } from 'src/utils/fileType';

export const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export const formatNumber = (number) => {
  // Check if the number has a decimal part
  if (number % 1 !== 0) {
    // If it has a decimal part, format it with two decimal places
    return number.toFixed(2);
  } else {
    // If it's an integer, display it without decimal places
    return number.toFixed(0);
  }
};

export const isValidURL = (url) => {
  var pattern = new RegExp('^https?:\\/\\/(www\\.)?([\\w-]+\\.)+[\\w]{2,}(\\/[\\w-]+)*\\/?.*$');
  return pattern.test(url);
};

export const getLastTwoDigitsAfterDecimal = (number) => {
  // Convert the number to a string
  if (!number) {
    return;
  }
  const numStr = number.toString();

  // Check if the number has a decimal point
  const decimalIndex = numStr.indexOf('.');
  if (decimalIndex === -1) {
    // If there's no decimal point, return the number as-is
    return number;
  }

  // Extract the last two characters after the decimal point
  const lastTwoDigits = numStr.slice(decimalIndex + 1, decimalIndex + 3);

  // Convert the last two digits back to a number
  const result = parseFloat(`${numStr.slice(0, decimalIndex)}.${lastTwoDigits}`);

  return result;
};

export const addDotsIfMoreThan = (str, limit) => {
  if (str?.length > limit) {
    return str?.slice(0, limit) + '...';
  }
  return str;
};
export const addDotsIfMoreThan15 = (str) => {
  return addDotsIfMoreThan(str, 15);
};
export const addDotsIfMoreThan10 = (str) => {
  return addDotsIfMoreThan(str, 10);
};

export const addDotsIfMoreThanValue = (str, value) => {
  return addDotsIfMoreThan(str, value);
};

export const addDotsIfMoreThan50 = (str) => {
  return addDotsIfMoreThan(str, 50);
};

export const isValidJwtFormat = (token) => {
  // Regular expression to match a JWT format
  const jwtRegex = /^[A-Za-z0-9-_]+\.[A-Za-z0-9-_]+\.[A-Za-z0-9-_]*$/;
  return jwtRegex.test(token);
};

export const formatToDDMMYYYY = (dateString) => {
  const date = moment(dateString, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
  return date.isValid() ? date.format('DD/MM/YYYY') : undefined;
};

export const addSpacesBeforeUppercase = (str) => {
  return str
    .split('')
    .map((char, index) => {
      if (index !== 0 && char === char.toUpperCase()) {
        return ` ${char}`;
      }
      return char;
    })
    .join('');
};

export const downloadAsExcel = async (data, rangeDate, sendNotification) => {
  const workbook = new ExcelJS.Workbook();
  const workSheetName = 'Worksheet-1';
  try {
    const workbooks = workbook.addWorksheet(workSheetName);
    workbooks.getColumn(1).width = 50;
    workbooks.getColumn(2).width = 50;
    const addedRows = [];

    const addConditionalRow = (condition: any, rowNumber, values, alignment) => {
      const isEmpty =
        condition === undefined ||
        condition === '' ||
        (Array.isArray(condition) && condition?.length === 0) ||
        condition.fromDate === '' ||
        condition.toDate === '';
      if (!isEmpty) {
        const row = workbooks.getRow(rowNumber);
        row.height = 30;
        row.values = values;
        row.alignment = alignment;
        addedRows.push(rowNumber);
      }
    };
    addedRows?.forEach((rowNumber) => {
      const row = workbooks.getRow(rowNumber);
      if (Array.isArray(row.values) && row.values.every((cell) => cell === null)) {
        workbooks.spliceRows(rowNumber, 1);
      }
    });

    addConditionalRow(rangeDate, 2, ['Selected Date Range', formatToDDMMYYYY(rangeDate?.fromDate) + ' - ' + formatToDDMMYYYY(rangeDate?.toDate)], {
      vertical: 'middle',
      horizontal: 'center'
    });

    const headerRow = workbooks.getRow(3);
    headerRow.values = [
      'Company',
      'Awaiting Response',
      'Drafting',
      'Conflicted',
      'Did Not Bid',
      'Submitted',
      'Lost',
      'Won',
      'Aborted',
      'Ended',
      'Seeking Approval for Waiver'
    ];
    headerRow.font = { bold: true };
    headerRow.height = 30;
    headerRow.alignment = { vertical: 'middle', horizontal: 'center' };

    data?.forEach((dataEntry) => {
      const dataRow = workbooks.addRow([]);
      dataRow.values = Object.values<any>(dataEntry).map((value) => (value === undefined || value === '' ? 0 : value));
      dataRow.eachCell({ includeEmpty: false }, (cell) => {
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
      });
    });

    workbooks.eachRow({ includeEmpty: false }, (row) => {
      row.eachCell({ includeEmpty: false }, (cell) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      });
    });
    const buf = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buf]), 'InvitationsAndOutcomes.xlsx');
    sendNotification({ message: 'Excel exported successfully.', variant: 'success' });
  } catch (error) {
    console.error('Something Went Wrong', error.message);
  } finally {
    workbook.removeWorksheet(workSheetName);
  }
};

export const downloadAsPdf = (id, TableId) => {
  const input = document.getElementById(id);
  html2canvas(input).then((canvas) => {
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF();

    pdf.addImage({
      imageData: imgData,
      format: 'PNG',
      x: 5,
      y: 5,
      width: 200,
      height: 130
    });
    pdf.addPage();
    const startYForTable = 10;
    autoTable(pdf, {
      html: `#${TableId}`,
      margin: { top: startYForTable, bottom: 10, left: 10, right: 10 },
      theme: 'grid',
      styles: {
        cellPadding: 3,
        fontSize: 10,
        valign: 'middle'
      },
      headStyles: {
        fillColor: [220, 220, 220],
        textColor: 50,
        minCellHeight: 0,
        fontStyle: 'bold'
      },
      bodyStyles: {
        minCellHeight: 15
      },
      didDrawCell: (data) => {
        if (data.row.section === 'head' && data.column.index === 1) {
          pdf.setDrawColor(190, 190, 191);
          pdf.setLineWidth(0.2);
          let finalY = data.cell.y + data.cell.height;
          pdf.line(data.cell.x, data.cell.y, data.cell.x, finalY);
        }
      }
    });
    pdf.save('InvitationOutcomes.pdf');
  });
};

export const validateExcelFileStructure = (workbook, requiredSheets, requiredColumns) => {
  const workbookSheetNames = workbook.worksheets.map((sheet) => sheet.name.trim());

  const allSheetsPresent = requiredSheets.every((requiredSheet) => workbookSheetNames.includes(requiredSheet.trim()));
  if (!allSheetsPresent) {
    return { isValid: false, message: 'Excel file is missing required sheets.' };
  }

  for (const sheetName of requiredSheets) {
    const sheet = workbook.getWorksheet(sheetName);
    if (!sheet) {
      return { isValid: false, message: `Required sheet "${sheetName}" is missing.` };
    }
    let requiredColumnsFound = false;
    sheet.eachRow((row) => {
      const rowValues = row.values.map((cell) => cell && cell.toString().trim().toLowerCase());
      if (rowValues && requiredColumns) {
        requiredColumnsFound = true;
      }
    });

    if (!requiredColumnsFound) {
      return { isValid: false, message: `Required columns are missing in sheet "${sheetName}".` };
    }
  }

  return { isValid: true, message: 'Excel file structure is valid.' };
};

export const getNestedProperty = (obj, path) => {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};

export const setNestedProperty = (obj, path, value) => {
  const parts = path.split('.');
  const lastPart = parts.pop();
  const lastObj = parts.reduce((acc, part) => {
    if (!acc[part]) acc[part] = {};
    return acc[part];
  }, obj);
  lastObj[lastPart] = value;
};

const removeDuplicatesAndEmptyRows = (dataArray) => {
  const uniqueRows = [];
  const uniqueRowsSet = new Set();

  dataArray.forEach((row) => {
    const stringifiedRow = JSON.stringify(row);
    if (!uniqueRowsSet.has(stringifiedRow) && row?.name !== '') {
      if (row?.nestedValue && (row?.name === row?.nestedValue?.name || row?.question === row?.nestedValue?.question)) {
        return;
      }
      uniqueRowsSet.add(stringifiedRow);
      uniqueRows.push(row);
    }
  });

  return uniqueRows;
};

export const removeDuplicatesAndEmptyRowsFromNestedData = (jsonData) => {
  // Iterate over each key in the jsonData object
  Object.keys(jsonData).forEach((key) => {
    // Check if the value is an array
    if (Array.isArray(jsonData[key])) {
      jsonData[key] = removeDuplicatesAndEmptyRows(jsonData[key]);
    } else if (typeof jsonData[key] === 'object') {
      jsonData[key] = removeDuplicatesAndEmptyRowsFromNestedData(jsonData[key]);
    }
  });

  return jsonData;
};

function updateObjectField(object, questionPath, acceptance, comment) {
  const path = questionPath.split('.').filter(Boolean);
  let currentObject = object;
  for (let i = 0; i < path?.length; i++) {
    const key = path[i];
    if (currentObject[key] !== undefined) {
      if (i === path?.length - 1) {
        currentObject[key].noted_accepted = acceptance;
        currentObject[key].comment = comment;
        return;
      }
      currentObject = currentObject[key];
    } else {
      console.error('Path not found:', questionPath);
      return;
    }
  }
}

export const updateBasedOnTemplateItem = (object, templateItem) => {
  if (templateItem && templateItem.question) {
    updateObjectField(object, templateItem.question, templateItem.acceptance, templateItem.comment);
  }
  if (templateItem.nestedValue && templateItem.nestedValue?.length > 0) {
    templateItem.nestedValue.forEach((nestedItem) => {
      const nestedQuestionPath = templateItem.question ? `${templateItem.question}.${nestedItem.question}` : nestedItem.question;
      updateObjectField(object, nestedQuestionPath, nestedItem.acceptance, nestedItem.comment);
    });
  }
};

const pricingByIndex = {
  1: 'name',
  2: 'question',
  3: 'acceptance',
  4: 'comment'
};
export const processRowForAcceptancePricing = (row, pricingRowData) => {
  row.eachCell({ includeEmpty: false }, (cell, colNumber) => {
    if (cell.worksheet._name === 'Pricing') {
      const cellValue = cell.value ? cell.value.toString().toLowerCase() : '';
      const pricingCellValues = cell?._row?._cells[0].value ? cell?._row?._cells[0].value.toString().toLowerCase() : '';
      if (
        pricingCellValues === 'Currency' ||
        pricingCellValues === 'No travel expected' ||
        pricingCellValues === 'Tax advice is excluded' ||
        pricingCellValues === 'Power Point slides' ||
        pricingCellValues === 'Following Jurisdiction/Language explicitly excluded from scope of work'
      ) {
        pricingRowData = {
          name: '',
          question: '',
          acceptance: false,
          comment: ''
        };
        row.eachCell({ includeEmpty: false }, (cellVal, index) => {
          pricingRowData[pricingByIndex[index]] = cellVal.value;
        });
      } else {
        if (colNumber <= 4) {
          switch (colNumber) {
            case 1:
              pricingRowData.name = cell.value ? cell.value.toString() : '';
              break;
            case 2:
              pricingRowData.question = cell.value ? cell.value.toString() : '';
              break;
            case 3:
              pricingRowData.acceptance = cell.value;
              // pricingRowData.acceptance = cell.value === 'yes' || cell.value === 'Yes' || cell.value === 'YES' ? true : false;
              break;
            case 4:
              pricingRowData.comment = cell.value ? cell.value.toString() : '';
              break;
          }
        } else {
          let index = Math.floor((colNumber - 5) / 4);
          let propertyIndex = (colNumber - 5) % 4;
          let value = cell.value ? cell.value.toString() : '';
          if (!Array.isArray(pricingRowData.nestedValue)) {
            pricingRowData.nestedValue = [];
          }

          if (typeof pricingRowData.nestedValue[index] === 'undefined') {
            pricingRowData.nestedValue[index] = {
              name: '',
              question: '',
              acceptance: false,
              comment: '',
              amount: ''
            };
          }
          switch (propertyIndex) {
            case 0:
              pricingRowData.nestedValue[index].name = value;
              break;
            case 1:
              pricingRowData.nestedValue[index].question = value;
              break;
            case 2:
              pricingRowData.nestedValue[index].acceptance = value;
              // pricingRowData.nestedValue[index].acceptance = value === 'yes' || value === 'Yes' || value === 'YES' ? true : false;
              break;
            case 3:
              pricingRowData.nestedValue[index].comment = value;
              break;
            case 4:
              pricingRowData.nestedValue[index].comment = value;
              break;
            case 5:
              pricingRowData.nestedValue[index].amount = value;
              break;
          }
        }
      }
    }
  });
};

export const processRowForEstimate = (row, pricingRowData) => {
  let colProcessed = 0;

  row.eachCell({ includeEmpty: false }, (cell, colNumber) => {
    if (cell.worksheet._name === 'Pricing') {
      if (colNumber <= 4) {
        switch (colNumber) {
          case 1:
            pricingRowData.name = cell.value ? cell.value.toString() : '';
            break;
          case 2:
            pricingRowData.question = cell.value ? cell.value.toString() : '';
            break;
          case 3:
            pricingRowData.amount = cell.value ? cell.value : '';
            break;
          case 4:
            pricingRowData.comment = cell.value ? cell.value.toString() : '';
            break;
        }
        colProcessed = colNumber;
      } else {
        let newIndex = Math.floor((colNumber - colProcessed - 1) / 2);
        pricingRowData.nestedValue = pricingRowData.nestedValue || [];
        pricingRowData.nestedValue[newIndex] = pricingRowData.nestedValue[newIndex] || { description: '', description_amount: '' };
        if (typeof pricingRowData.nestedValue[newIndex] === 'undefined') {
          pricingRowData.nestedValue[newIndex] = {
            description: '',
            description_amount: ''
          };
        }

        if ((colNumber - colProcessed - 1) % 2 === 0) {
          pricingRowData.nestedValue[newIndex].description = cell.value ? cell.value.toString() : '';
        } else {
          pricingRowData.nestedValue[newIndex].description_amount = cell.value ? cell.value.toString() : '';
        }
      }
    }
  });
};
export const processRowForFourConceptAlternativePricing = (row, pricingRowData) => {
  let colProcessed = 0;

  row.eachCell({ includeEmpty: false }, (cell, colNumber) => {
    if (cell.worksheet._name === 'Pricing') {
      if (colNumber <= 4) {
        switch (colNumber) {
          case 1:
            pricingRowData.name = cell.value ? cell.value.toString() : '';
            break;
          case 2:
            pricingRowData.question = cell.value ? cell.value.toString() : '';
            break;
          case 3:
            pricingRowData.acceptance = cell.value === 'yes' || cell.value === 'Yes' || cell.value === 'YES' ? true : false;
            break;
          case 4:
            pricingRowData.comment = cell.value ? cell.value.toString() : '';
            break;
        }
        colProcessed = colNumber;
      }
      //  else {
      //   let newIndex = Math.floor((colNumber - colProcessed - 1) / 2);
      //   pricingRowData.nestedValue = pricingRowData.nestedValue || [];
      //   pricingRowData.nestedValue[newIndex] = pricingRowData.nestedValue[newIndex] || { description: '', description_amount: '' };
      //   if (typeof pricingRowData.nestedValue[newIndex] === 'undefined') {
      //     pricingRowData.nestedValue[newIndex] = {
      //       description: '',
      //       description_amount: ''
      //     };
      //   }

      //   if ((colNumber - colProcessed - 1) % 2 === 0) {
      //     pricingRowData.nestedValue[newIndex].description = cell.value ? cell.value.toString() : '';
      //   } else {
      //     pricingRowData.nestedValue[newIndex].description_amount = cell.value ? cell.value.toString() : '';
      //   }
      // }
    }
  });
};
export const processRowForTwoConceptAlternativePricing = (row, pricingRowData) => {
  let colProcessed = 0;

  row.eachCell({ includeEmpty: false }, (cell, colNumber) => {
    if (cell.worksheet._name === 'Pricing') {
      if (colNumber <= 4) {
        switch (colNumber) {
          case 1:
            pricingRowData.name = cell.value ? cell.value.toString() : '';
            break;
          case 2:
            pricingRowData.question = cell.value ? cell.value.toString() : '';
            break;
          case 3:
            pricingRowData.amount = cell.value ? cell.value : '';
            break;
          case 4:
            pricingRowData.comment = cell.value ? cell.value.toString() : '';
            break;
        }
        colProcessed = colNumber;
      }
      //  else {
      //   let newIndex = Math.floor((colNumber - colProcessed - 1) / 2);
      //   pricingRowData.nestedValue = pricingRowData.nestedValue || [];
      //   pricingRowData.nestedValue[newIndex] = pricingRowData.nestedValue[newIndex] || { description: '', description_amount: '' };
      //   if (typeof pricingRowData.nestedValue[newIndex] === 'undefined') {
      //     pricingRowData.nestedValue[newIndex] = {
      //       description: '',
      //       description_amount: ''
      //     };
      //   }

      //   if ((colNumber - colProcessed - 1) % 2 === 0) {
      //     pricingRowData.nestedValue[newIndex].description = cell.value ? cell.value.toString() : '';
      //   } else {
      //     pricingRowData.nestedValue[newIndex].description_amount = cell.value ? cell.value.toString() : '';
      //   }
      // }
    }
  });
};
export const processRowForBlendedPricing = (row, pricingRowData) => {
  let colProcessed = 0;

  row.eachCell({ includeEmpty: false }, (cell, colNumber) => {
    if (cell.worksheet._name === 'Pricing') {
      if (colNumber <= 3) {
        switch (colNumber) {
          case 1:
            pricingRowData.name = cell.value ? cell.value.toString() : '';
            break;
          case 2:
            pricingRowData.question = cell.value ? cell.value.toString() : '';
            break;
          case 3:
            pricingRowData.comment = cell.value ? cell.value.toString() : '';
            break;
        }
        colProcessed = colNumber;
      } else {
        let newIndex = Math.floor((colNumber - colProcessed - 1) / 2);
        pricingRowData.nestedValue = pricingRowData.nestedValue || [];
        pricingRowData.nestedValue[newIndex] = pricingRowData.nestedValue[newIndex] || { description: '', description_amount: '' };
        if (typeof pricingRowData.nestedValue[newIndex] === 'undefined') {
          pricingRowData.nestedValue[newIndex] = {
            description: '',
            description_amount: ''
          };
        }

        if ((colNumber - colProcessed - 1) % 2 === 0) {
          pricingRowData.nestedValue[newIndex].description = cell.value ? cell.value.toString() : '';
        } else {
          pricingRowData.nestedValue[newIndex].description_amount = cell.value ? cell.value.toString() : '';
        }
      }
    }
  });
};
export const processRowForTotalFee = (row, pricingRowData) => {
  row.eachCell({ includeEmpty: false }, (cell, colNumber) => {
    if (cell.worksheet._name === 'Pricing') {
      if (colNumber) {
        switch (colNumber) {
          case 1:
            pricingRowData.name = cell.value ? cell.value.toString() : '';
            break;
          case 2:
            pricingRowData.question = cell.value ? cell.value.toString() : '';
            break;
          case 3:
            pricingRowData.amount = cell.value ? cell.value : '';
            break;
          case 4:
            pricingRowData.description = cell.value ? cell.value.toString() : '';
            break;
        }
      }
    }
  });
};
export const processRowForAltCheckBoxComponents = (row, pricingRowData) => {
  let colProcessed = 0;

  row.eachCell({ includeEmpty: false }, (cell, colNumber) => {
    if (cell.worksheet._name === 'Pricing') {
      if (colNumber <= 4) {
        switch (colNumber) {
          case 1:
            pricingRowData.name = cell.value ? cell.value.toString() : '';
            break;
          case 2:
            pricingRowData.question = cell.value ? cell.value.toString() : '';
            break;
          case 3:
            pricingRowData.amount = cell.value ? cell.value : '';
            break;
          case 4:
            pricingRowData.comment = cell.value ? cell.value.toString() : '';
            break;
        }
        colProcessed = colNumber;
      } else {
        let newIndex = Math.floor((colNumber - colProcessed - 1) / 2);
        pricingRowData.nestedValue = pricingRowData.nestedValue || [];
        pricingRowData.nestedValue[newIndex] = pricingRowData.nestedValue[newIndex] || { description: '', description_amount: '' };
        if (typeof pricingRowData.nestedValue[newIndex] === 'undefined') {
          pricingRowData.nestedValue[newIndex] = {
            description: '',
            description_amount: ''
          };
        }

        if ((colNumber - colProcessed - 1) % 2 === 0) {
          pricingRowData.nestedValue[newIndex].description = cell.value ? cell.value.toString() : '';
        } else {
          pricingRowData.nestedValue[newIndex].description_amount = cell.value ? cell.value : '';
        }
      }
    }
  });
};

export const processRowForOtherPricing = (row, pricingRowData) => {
  row.eachCell({ includeEmpty: false }, (cell, colNumber) => {
    if (cell.worksheet._name === 'Pricing') {
      if (colNumber <= 4) {
        switch (colNumber) {
          case 1:
            pricingRowData.name = cell.value ? cell.value.toString() : '';
            break;
          case 2:
            pricingRowData.question = cell.value ? cell.value.toString() : '';
            break;
          case 3:
            pricingRowData.acceptance = cell.value;
            break;
          case 4:
            pricingRowData.comment = cell.value ? cell.value.toString() : '';
            break;
        }
      } else {
        let index = Math.floor((colNumber - 5) / 4);
        pricingRowData.nestedValue = pricingRowData.nestedValue || [];
        pricingRowData.nestedValue[index] = pricingRowData.nestedValue[index] || {
          comment: '',
          question: '',
          acceptance: false,
          name: '',
          keys: {}
        };

        let value = cell.value ? cell.value.toString() : '';
        switch ((colNumber - 5) % 4) {
          case 0:
            pricingRowData.nestedValue[index].name = value;
            pricingRowData.nestedValue[index].keys = rfpNameSchema?.find((data) => data?.label?.toLowerCase() === value?.toLowerCase());
            break;
          case 1:
            pricingRowData.nestedValue[index].question = value;
            break;
          case 2:
            pricingRowData.nestedValue[index].acceptance = value;
            break;
          case 3:
            pricingRowData.nestedValue[index].comment = value;
            break;
        }
      }
    }
  });
};

export const updatePricingModelWithServerData = (newProposalInitialValues, serverItem) => {
  let updatedNestedValues;
  let modelKey = serverItem.name.toLowerCase().replace(/ /g, '_').replace('for_project', '').replace('up_to_a_certain_amount', '');
  let model = newProposalInitialValues.pricing[0].model[modelKey];
  if (model) {
    let detailsArray = [];
    if (
      serverItem.name === 'Blended Fee' ||
      serverItem.name === 'Contingent Fee' ||
      serverItem.name === 'Abort Discount' ||
      serverItem.name === 'Success Fee' ||
      serverItem.name === 'Budget for project' ||
      serverItem.name === 'Other' ||
      serverItem.name === 'Bidders to indicate any applicable tax to fees and %' ||
      serverItem.name === 'Free initial advice up to a certain amount'
    ) {
      const description = serverItem.description || '';
      const amount = serverItem.description_amount || null;
      detailsArray.push({ description, amount });

      if (serverItem.nestedValue && serverItem.nestedValue.length > 0) {
        serverItem.nestedValue.slice(1).forEach((nestedItem) => {
          const nestedDescription = nestedItem.description || '';
          const nestedAmount = nestedItem.amount || null;
          detailsArray.push({ description: nestedDescription, amount: nestedAmount });
        });
      }
      if (model.alternative_or_additional_pricing_benefits) {
        model.alternative_or_additional_pricing_benefits.details = detailsArray;
      } else {
        model.details = detailsArray;
      }
    } else {
      if (serverItem.nestedValue && serverItem.nestedValue.length > 0) {
        detailsArray = serverItem.nestedValue
          .filter((nv) => nv.description !== '' || nv.amount.toString() !== '')
          .slice(0, 1)
          .map((nv) => ({
            description: nv.description || nv.comment || '',
            amount: nv.amount || nv.comment || null
          }));

        if (model.alternative_or_additional_pricing_benefits) {
          model.alternative_or_additional_pricing_benefits.details = detailsArray;
        } else {
          model.details = detailsArray;
        }
      }
    }
    // Update the 'value' flag based on the presence of details
    const details = model.alternative_or_additional_pricing_benefits ? model.alternative_or_additional_pricing_benefits.details : model.details;
    if (details?.length > 0) {
      if (model.alternative_or_additional_pricing_benefits) {
        model.alternative_or_additional_pricing_benefits.value = true;
      }
    } else if (model.alternative_or_additional_pricing_benefits) {
      model.alternative_or_additional_pricing_benefits.value = false;
    }
  }
};

export const updatePricingAlternativeBenefits = (newProposalInitialValues, serverItem) => {
  let modelKey = serverItem.question;
  let model = newProposalInitialValues.pricing[0].model[modelKey];
  if (model) {
    let detailsArray = [];
    if (serverItem.nestedValue && serverItem.nestedValue.length > 0) {
      detailsArray = serverItem?.nestedValue
        .filter((nv) => nv?.description !== '' || nv?.amount?.toString() !== '')
        .slice(0, 5)
        .map((nv) => ({
          description: nv.description || '',
          amount: nv.description_amount || null
        }));
      if (model.breakdown_phases) {
        model.breakdown_phases = detailsArray;
      } else {
        model.details = detailsArray;
      }
    }
    // const details = model.alternative_or_additional_pricing_benefits ? model.alternative_or_additional_pricing_benefits.details : model.details;
    // if (details?.length > 0) {
    //   if (model.alternative_or_additional_pricing_benefits) {
    //     model.alternative_or_additional_pricing_benefits.value = true;
    //   }
    // } else if (model.alternative_or_additional_pricing_benefits) {
    //   model.alternative_or_additional_pricing_benefits.value = false;
    // }
  }
};

export const updateJsonDataWithArrayKeys = (jsonData, dataFormat) => {
  const updatedJsonData = JSON.parse(JSON.stringify(jsonData));
  const updateSection = (section) => {
    section.forEach((item) => {
      const matchedDataArrayItem = dataFormat.find((data) => data.label === item.name);
      if (matchedDataArrayItem) {
        item.question = matchedDataArrayItem.key;
      } else {
        item.question = '';
      }
    });
  };

  const updateNestedSection = (nestedSection) => {
    nestedSection.forEach((nestedItem) => {
      const titleKey = 'name';
      if (nestedItem && nestedItem[titleKey]) {
        const matchedDataArrayItem = dataFormat.find((data) => data.label === nestedItem[titleKey]);
        if (matchedDataArrayItem) {
          nestedItem.question = matchedDataArrayItem.key;
        } else {
          nestedItem.question = '';
        }
      }
    });
  };

  for (const section in updatedJsonData) {
    if (Array.isArray(updatedJsonData[section])) {
      updateSection(updatedJsonData[section]);
      updatedJsonData[section].forEach((obj) => {
        if (obj.question === 'Terms & Conditions' || Array.isArray(obj.nestedValue)) {
          updateNestedSection(obj.nestedValue);
        }
      });
    }
  }

  return updatedJsonData;
};

export const addLeadPartnerMemberRow = (data, otherKeyInfoWorksheet) => {
  const leadPartnerHeaders = ['Title', 'Name', 'Surname', 'Location', 'Email', 'Title', 'Role', 'Year of Experience', 'Hourly Rate'];
  const headerRow = otherKeyInfoWorksheet.addRow(leadPartnerHeaders);
  headerRow.font = { bold: true, color: { argb: 'FF000000' } };
  headerRow.eachCell((cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFFFFFF' }
    };
    cell.alignment = {
      vertical: 'middle',
      horizontal: 'center'
    };
    cell.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    };
  });
  const dataRowValues = [data.header, data.name, data.surname, data.location, data.email, data.title, data.role, data.year_of_experience, data.hourly_rate];
  const dataRow = otherKeyInfoWorksheet.addRow(dataRowValues);
  dataRow.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
  dataRow.eachCell((cell) => {
    cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
  });
};

export const addFileRow = (workbook, rowIndex, fileName) => {
  const fileRow = workbook.getRow(rowIndex);
  fileRow.getCell(1).value = 'Related Files';
  fileRow.getCell(2).value = fileName;
  fileRow.height = 20;
  fileRow.getCell(1).font = { bold: true };

  fileRow.eachCell((cell) => {
    cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
  });

  fileRow.commit();
  return rowIndex;
};
export const addVideoForOtherKeyInfoRow = (workbook, rowIndex, aditionalInfoValue) => {
  const fileRow = workbook.getRow(rowIndex);
  fileRow.getCell(1).value = 'Video files';
  fileRow.getCell(2).value = aditionalInfoValue;
  fileRow.height = 20;
  fileRow.getCell(1).font = { bold: true };

  fileRow.eachCell((cell) => {
    cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
  });

  fileRow.commit();
  return rowIndex;
};

export const addAdditionalInformationRow = (worksheet, additionalInfoValue) => {
  const titleRow = worksheet.addRow(['Title', 'Comment']);
  titleRow.eachCell((cell) => {
    cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
    cell.font = { bold: true };
  });

  const headerRow = worksheet.addRow(['Additional Information', additionalInfoValue]);
  headerRow.getCell(2).value = additionalInfoValue;
  headerRow.eachCell((cell, colNumber) => {
    cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
    cell.font = { bold: colNumber === 1 };
  });

  worksheet.columns.forEach((column) => {
    column.width = 20;
    column.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
  });

  worksheet.eachRow({ includeEmpty: false }, function (row) {
    row.eachCell({ includeEmpty: false }, function (cell) {
      cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
    });
  });

  return worksheet.lastRow.number + 1;
};

export const estimateFeeScenario = (data) => {
  const headers = ['Estimate', 'Fixed Fee', 'Capped Fee', 'Total Fee'];
  return headers.includes(data.header);
};
export const isFirstScenario = (data) => {
  const headers = ['Blended Fee', 'Contingent Fee', 'Abort Discount', 'Success Fee', 'Free initial advice up to a certain amount'];
  return headers.includes(data.header);
};
export const isSecondScenario = (data) => {
  const headers = [
    'Reverse Auction (To be conducted by Corporate outside of Zaven)',
    'Retainer (general/project based)',
    'Pricing Other',
    'Bidders to indicate any applicable tax to fees and %'
  ];
  return headers.includes(data.header);
};
// export function formatCredentials(htmlString) {
//   const parser = new DOMParser();
//   const doc = parser.parseFromString(htmlString, 'text/html');

//   const strongTags = doc.querySelectorAll('strong');
//   const credentials = Array.from(strongTags).map((tag) => tag.textContent.trim());

//   return credentials.join(',');
// }

export function formatCredentials(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  const elements = doc.querySelectorAll('strong, em, li, p, span, div');

  let credentials = [];

  elements.forEach((element) => {
    if (element.nodeName === 'LI') {
      const text = element.textContent.trim();
      if (text.match(/^\d/)) {
        credentials.push(text);
      }
    } else {
      const text = element.textContent.trim();
      if (text) {
        credentials.push(text);
      }
    }
  });

  return credentials.join(',');
}

export const compareStrings = (str1, str2) => {
  const normalizeString = (str) => str?.replace(/\s/g, '').toLowerCase();
  return normalizeString(str1) === normalizeString(str2);
};

export const expandByChildPath = (proposalValues, displayFlags, path, displayKey) => {
  let valueAtPath = path.split('.').reduce((obj, key) => (obj && key in obj ? obj[key] : null), proposalValues);
  displayFlags[displayKey] = !!valueAtPath && valueAtPath.trim() !== '';
};

export const convertQuestionToPath = (question) => {
  switch (question) {
    case 'structuring':
      return 'phase_one.structuring';
    case 'term_sheet':
      return 'phase_one.term_sheet';
    case 'marketing':
      return 'phase_two.marketing';
    case 'reviewing_pitch_book':
      return 'phase_two.marketing.reviewing_pitch_book';
    case 'marketing_advice':
      return 'phase_two.marketing.marketing_advice';
    case 'fund_interests':
      return 'phase_two.marketing.marketing_advice.fund_interests';
    case 'non_us_eu_jurisdictions':
      return 'phase_two.marketing.marketing_advice.non_us_eu_jurisdictions';
    case 'marketing_in_us':
      return 'phase_two.marketing.marketing_advice.marketing_in_us';
    case 'private_placement_exemptions':
      return 'phase_two.marketing.marketing_advice.marketing_in_us.private_placement_exemptions';
    case 'us_regulation_filings':
      return 'phase_two.marketing.marketing_advice.marketing_in_us.us_regulation_filings';
    case 'us_securities_law':
      return 'phase_two.marketing.marketing_advice.marketing_in_us.us_securities_law';
    case 'drafting_ppm':
      return 'phase_three.drafting_ppm';
    case 'core_documents':
      return 'phase_three.core_documents';
    case 'ancilliary_documents':
      return 'phase_three.ancilliary_documents';
    case 'entity_establishment':
      return 'phase_four.entity_establishment';
    case 'negotiations':
      return 'phase_four.negotiations';
    case 'closings':
      return 'phase_four.closings';
    case 'post_closing':
      return 'phase_five.post_closing';
    case 'optional_workstreams':
      return 'phase_five.optional_workstreams';
    default:
      return question;
  }
};
export const updateFundFormationObject = (fundFormationObject, templateItem) => {
  function setValueByPath(obj, path, acceptance, comment) {
    const keys = path.split('.');
    let current = obj;
    for (let i = 0; i < keys.length - 1; i++) {
      if (current[keys[i]] === undefined) {
        return;
      }
      current = current[keys[i]];
    }
    const lastKey = keys[keys.length - 1];
    if (current[lastKey] !== undefined) {
      // Check if the target property exists
      current[lastKey].noted_accepted = acceptance;
      current[lastKey].comment = comment;
    }
  }

  if (typeof templateItem === 'object' && templateItem !== null) {
    Object.entries(templateItem).forEach(() => {
      const path = convertQuestionToPath(templateItem.question);
      setValueByPath(fundFormationObject, path, templateItem.acceptance, templateItem.comment);
    });
  } else {
    console.error('templateItem is not an object or is null');
  }
  if (templateItem.nestedValue && templateItem.nestedValue.length > 0) {
    templateItem.nestedValue.forEach((nestedItem) => {
      const path = convertQuestionToPath(nestedItem.question);
      setValueByPath(fundFormationObject, path, nestedItem.acceptance, nestedItem.comment);
    });
    if (typeof templateItem.nestedValue === 'object' && templateItem.nestedValue !== null) {
      Object.entries(templateItem.nestedValue).forEach(() => {
        const path = convertQuestionToPath(templateItem.question);
        setValueByPath(fundFormationObject, path, templateItem.acceptance, templateItem.comment);
      });
    }
  }
};

export const validateAllFileFormat = async (fileObj) => {
  if (!fileObj) {
    return 'No file selected.';
  }
  if (fileObj?.size > 5 * 1024 * 1024) {
    return 'Maximum file size is 5mb';
  }
  if (fileObj?.type === 'application/pdf') {
    const errorCheck: any = await maliciousPDFCheck(fileObj);
    if (errorCheck.containsScript || errorCheck.containsPotentiallyHarmful || errorCheck.containsAnnotationError) {
      return 'The file contains malicious content.';
    }
  }
  if (!allowedFileTypes?.includes(fileObj?.type)) {
    return 'Invalid file type. Please upload a file of type: PDF, PNG, JPEG, Word, Excel, PowerPoint, Plain Text, or MP4.';
  }
  return null;
};

export const validateSpecificImageFormat = async (fileObj) => {
  if (!fileObj) {
    return 'No Image selected.';
  }
  const firstDotIndex = fileObj.name.indexOf('.');
  const remainingName = fileObj.name.substring(firstDotIndex + 1);
  if (remainingName.includes('.')) {
    return 'Invalid Image type. Please upload a Image of type: JPG, JPEG, PNG.';
  }
  if (fileObj.size / 1024 > 500) {
    return 'Maximum file size is 500kb';
  }
  if (!allowedImgTypes.includes(fileObj.type)) {
    return 'Only JPEG, JPG, and PNG file formats are allowed.';
  }
  return null;
};

export const validateSpecificFileFormat = async (fileObj) => {
  if (!fileObj) {
    return 'No file selected.';
  }
  if (fileObj.size > 5 * 1024 * 1024) {
    return 'Maximum file size is 5mb';
  }
  if (fileObj.type === 'application/pdf') {
    const errorCheck: any = await maliciousPDFCheck(fileObj);
    if (errorCheck.containsScript || errorCheck.containsPotentiallyHarmful || errorCheck.containsAnnotationError) {
      return 'The file contains malicious content.';
    }
  }
  if (!allowedDocsTypes.includes(fileObj.type)) {
    return 'Only PDF and DOCS file formats are allowed.';
  }
  return null;
};

export const checkNestedValues = (obj) => {
  if (!obj || typeof obj !== 'object') return false;

  if (obj.hasOwnProperty('value') && obj.value) return true;

  for (let key in obj) {
    if (checkNestedValues(obj[key])) return true;
  }

  return false;
};
